import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';

import * as theme from '../css-modules/AlbumsListPage.module.css';
import { getAlbumTitle } from './AlbumTitle';
import Logo from './Logo';
import { fetchBucketData } from '../utils/s3';

type ConfigJson = {
  [albumName: string]: {
    visible: boolean;
  };
};

const AlbumList: React.FC<{ path: string }> = ({}) => {
  const [bucketData, setBucketData] = useState<string[] | null>(null);
  const [configJson, setConfigJson] = useState<ConfigJson | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const fetchConfigJson = () => {
    window
      .fetch('https://photos.willsphotosite.com/config.json')
      .then((response) => response.json())
      .then((json) => setConfigJson(json));
  };

  useEffect(() => {
    fetchConfigJson();
    fetchBucketData({
      // Filter out image entities, leaving only the directory entities
      filterFunc: (key: string | undefined): key is string =>
        !!(key && !/\.json$/i.test(key)),
    }).then((objectKeys) => {
      setBucketData(objectKeys);
    });
    setIsMounted(true);
  }, []);

  const albumsInclDuplicates = bucketData?.map((objectKey) => {
    return objectKey.split('/')[0];
  });
  const albums = [...new Set(albumsInclDuplicates)];

  return (
    /* Prevent page from being server-side rendered due to Gatsby ssr'ing this page for
     * each AlbumPage */
    isMounted ? (
      <div className={theme.AlbumsListPage}>
        <h1 className={theme.AlbumsListHeader}>
          <Logo
            className={theme.LogoSvg}
            onClick={() => {
              navigate('/');
            }}
          />{' '}
          willsphotosite
        </h1>
        {albums &&
          configJson &&
          albums
            .filter((albumName) => configJson[albumName]?.visible !== false)
            .map((albumName) => (
              <div key={albumName}>
                <Link to={`/albums/${albumName}`}>
                  {getAlbumTitle(albumName)}
                </Link>
              </div>
            ))}
      </div>
    ) : null
  );
};

export default AlbumList;
