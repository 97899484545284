import React from 'react';

const Page404: React.FC = () => {
  return (
    <div id="notfound">
      <style>
        {`
          #notfound {
            position: relative;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }

          #notfound .notfound {
          }

          .notfound {
          }

          .notfound .notfound-404 {
            width: 140px;
            height: 140px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .notfound .notfound-404:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-transform: scale(2.4);
                -ms-transform: scale(2.4);
                    transform: scale(2.4);
            border-radius: 50%;
            background-color: #f2f5f8;
            z-index: -1;
          }

          .notfound .notfound-404:after {
            content: '😵‍💫';
            font-size: 140px;
            opacity: 0.9;
          }

          .notfound h1 {
            font-size: 65px;
            font-weight: 700;
            margin-top: 0px;
            margin-bottom: 30px;
            color: #151723;
            text-transform: uppercase;
          }

          .notfound h2 {
            font-size: 21px;
            font-weight: 400;
            margin: 0;
            text-transform: uppercase;
            color: #151723;
          }

          .notfound p {
            color: #999fa5;
            font-weight: 400;
          }

          .notfound a {
            display: inline-block;
            font-weight: 700;
            border-radius: 40px;
            text-decoration: none;
            color: #388dbc;
          }
        `}
      </style>
      <div className="notfound">
        <div className="notfound-404"></div>
        <h1>404</h1>
        <h2>Page Not Found</h2>
      </div>
    </div>
  );
};

export default Page404;
