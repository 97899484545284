import React from 'react';
import AlbumsListPage from '../../components/AlbumsListPage';
import { Router } from '@reach/router';
import AlbumPage from '../../components/AlbumPage';
import '@fontsource/dosis/200.css';
import '@fontsource/dosis/300.css';
import '@fontsource/dosis/400.css';
import '@fontsource/dosis/500.css';
import '@fontsource/dosis/600.css';
import '@fontsource/dosis/700.css';
import '@fontsource/dosis/800.css';
import { getAlbumTitle } from '../../components/AlbumTitle';

export default function Albums({}) {
  return (
    <Router basepath="/albums">
      <AlbumsListPage path="/" />
      <AlbumPage path="/:albumName" />
    </Router>
  );
}

export function Head(props) {
  const albumTitle =
    typeof window !== 'undefined'
      ? window.location.href.split('/').pop()
      : null;
  const staticOGImageUrl =
    'https://photos.willsphotosite.com/seven-lakes-wilderness/019A6423.jpg';
  const albumTitleDisplay = albumTitle ? getAlbumTitle(albumTitle) : null;
  const resolvedTitle = albumTitleDisplay || "Will's Photosite";
  return (
    <>
      <title>{resolvedTitle}</title>
      <meta
        property="og:image"
        content={
          albumTitle && albumTitle !== 'albums'
            ? `https://photos.willsphotosite.com/${albumTitle}/photo-0.jpg`
            : staticOGImageUrl
        }
      />
      <meta property="og:image:width" content={'4000'} />
      <meta property="og:image:height" content={'2667'} />
      <meta property="og:title" content={resolvedTitle} />
    </>
  );
}
