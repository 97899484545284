// extracted by mini-css-extract-plugin
export var AlbumPage = "AlbumPage-module--AlbumPage--4b2b6";
export var AlbumPageFull = "AlbumPage-module--AlbumPageFull--ec68c";
export var AlbumPageHeader = "AlbumPage-module--AlbumPageHeader--834c4";
export var CloseIcon = "AlbumPage-module--CloseIcon--6a578";
export var Image = "AlbumPage-module--Image--cd078";
export var ImageClickable = "AlbumPage-module--ImageClickable--2f779";
export var ImageList = "AlbumPage-module--ImageList--aca12";
export var ImageListItem = "AlbumPage-module--ImageListItem--61270";
export var ImagePlaceholder = "AlbumPage-module--ImagePlaceholder--fed5e";
export var LogoSvg = "AlbumPage-module--LogoSvg--70e85";
export var OffscreenImg = "AlbumPage-module--OffscreenImg--72d85";
export var PhotoCount = "AlbumPage-module--PhotoCount--d92d2";
export var Shown = "AlbumPage-module--Shown--04007";
export var breatheDark = "AlbumPage-module--breatheDark--10ba4";
export var breatheLight = "AlbumPage-module--breatheLight--1b503";
export var fadeIn = "AlbumPage-module--fadeIn--197b3";