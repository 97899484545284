import React from 'react';
import classNames from 'classnames';
import { findDOMNode } from 'react-dom';
import throttle from 'lodash.throttle';
import scrollIntoView from 'scroll-into-view';

import * as theme from '../css-modules/LazilyRenderedList.module.css';
import LazilyRenderedComponent from './LazilyRenderedComponent';

/* Only report items in viewport once scrolling stops */
const REPORT_ITEMS_IN_VIEWPORT_DELAY = 300;
const REACHED_END_BUFFER = 100;

interface ItemsDimensionsStyleBase {
  width: string;
  display?: string;
}

interface ItemsDimensionsStyleBaseWithMinHeight
  extends ItemsDimensionsStyleBase {
  minHeight: string;
}
interface ItemsDimensionsStyleBaseWithHeight extends ItemsDimensionsStyleBase {
  height: string;
}

type Props = {
  /* The field on the child to use as a React key */
  llKeyField: string;
  horizontal?: boolean;
  /* The number of px above and below the viewport at which a child item should be rendered */
  preloadBuffer?: number;
  /* Optional callback that fires when a child item enters the viewport, executed 200ms after scroll stops */
  handleReportItemsInViewport?: (llKey: string[], position: number) => void;
  /* Child items must have a defined width and height for lazy loading calculation */
  itemDimensionsStyle:
    | ItemsDimensionsStyleBaseWithMinHeight
    | ItemsDimensionsStyleBaseWithHeight;
  useParentAsScrollableAncestor?: boolean;
  /* Trigger to cause recalculation of which child elements should be rendered, useful when
   * the parent container size changes and more child elements might need to be rendered */
  updateItemsToRenderTrigger?: any;
  /* Event to fire when the bottom of the scrollable area has been reached.  Can be used to fetch
   * the next page of list items from the API and update the passed-in children. */
  onReachedEnd?: () => void;
  /* An event handler called on scroll (throttled to 100 ms intervals) */
  onScroll?: () => void;
  /* Callback called with total items rendered after each new batch of items are rendered */
  onRenderItems?: (llKey: string[]) => void;
  /* The key of an item that should be scrolled to */
  keyToScroll?: string | null;
  /* Trigger to cause scrollable container to scroll to top */
  scrollToTopTrigger?: any;
  shouldScrollToTopOnChildChange?: boolean;
  /* Whether items should stay rendered once they're rendered initially. i.e. after scrolling down a
   * long list and reaching the bottom, all items stay rendered no matter the scroll position of the list.
   * This is useful when it's more costly to render items than keep them in the DOM, like a grid of photos */
  shouldKeepItemsRendered?: boolean;
  className?: string;
  children: React.ReactNode;
  itemClassName?: string;
  triggerScrollToKey?: boolean;
  style?: React.CSSProperties;
};

type State = {
  itemComponentsToRender: React.ReactNode[];
};

function getIsReactElement(reactChild: any): reactChild is React.ReactElement {
  return (reactChild as React.ReactElement).props !== undefined;
}

/*
 * A component that only renders child items that are inside of the viewport.  Can optionally be
 * used to handle loading more items when the bottom of the list has been scrolled to.
 */
class LazilyRenderedList extends React.PureComponent<Props, State> {
  state: State = {
    itemComponentsToRender: [],
  };
  itemNodesById: { [id: string]: React.ReactNode } = {};
  renderItemsScrollBinding: (() => void) | null = null;
  /* Memoize this to over recalculating both the prev and current checksum on componentDidUpdate */
  childChecksum: string | null = null;
  removeEmphasizedClassTimeout: number | null = null;
  scrollableAncestor: HTMLElement | null = null;
  handleReportItemsInViewportTimeout: number | null = null;

  componentDidMount() {
    this.setScrollableAncestor();
    if (this.scrollableAncestor) {
      this.setupEventsAndReportItems();
    }
    this.childChecksum = this.getChildChecksum(this.props.children);
    if (this.props.keyToScroll) {
      this.scrollToItem(this.props.keyToScroll);
    }
  }

  componentWillUnmount() {
    if (this.scrollableAncestor) {
      this.removeEventListeners(this.scrollableAncestor);
    }
    if (this.removeEmphasizedClassTimeout) {
      window.clearTimeout(this.removeEmphasizedClassTimeout);
    }
    this.scrollableAncestor = null;
  }

  componentDidUpdate(prevProps) {
    const newChildChecksum = this.getChildChecksum(this.props.children);

    if (
      /* When we need to recalculate which child elements are rendered */
      newChildChecksum !== this.childChecksum ||
      prevProps.updateItemsToRenderTrigger !==
        this.props.updateItemsToRenderTrigger
    ) {
      this.readAndReportItemsInViewport();
      this.setItemsToRender();
    }
    if (this.props.scrollToTopTrigger !== prevProps.scrollToTopTrigger) {
      this.scrollListToTop();
    }
    if (
      this.props.keyToScroll &&
      this.props.keyToScroll !== prevProps.keyToScroll
    ) {
      this.scrollToItem(this.props.keyToScroll);
    }
    this.childChecksum = newChildChecksum;
  }

  setScrollableAncestor = (): void => {
    const { useParentAsScrollableAncestor } = this.props;

    this.scrollableAncestor = useParentAsScrollableAncestor
      ? findDOMNode(this).parentElement
      : window;
  };

  /* Scroll to an item in the list when `keyToScroll` provided and item is present in list */
  scrollToItem = (key: string): void => {
    const node = findDOMNode(this);
    const { horizontal } = this.props;
    const itemToScroll = node.querySelector(`[data-scroll-to-key="${key}"]`);
    const offsetSize = this.getScrollableAncestorSize();

    if (this.scrollableAncestor?.constructor === window.Window && horizontal) {
      window.scrollTo(itemToScroll.offsetLeft, 0);
    } else if (
      this.scrollableAncestor?.constructor === window.Window &&
      !horizontal
    ) {
      window.scrollTo(0, itemToScroll.offsetTop);
    } else if (this.scrollableAncestor && horizontal) {
      this.scrollableAncestor.scrollLeft = itemToScroll.offsetLeft;
    } else if (this.scrollableAncestor) {
      this.scrollableAncestor.scrollTop = itemToScroll.offsetTop;
    }
  };

  scrollListToTop = (): void => {
    const { horizontal } = this.props;
    if (this.scrollableAncestor?.constructor === window.Window) {
      window.scrollTo(0, 0);
    } else if (this.scrollableAncestor && horizontal) {
      this.scrollableAncestor.scrollLeft = 0;
    } else if (this.scrollableAncestor) {
      this.scrollableAncestor.scrollTop = 0;
    }
  };

  getScrollableAncestorScrollPosition = (): number | null => {
    const { horizontal } = this.props;
    if (this.scrollableAncestor?.constructor === window.Window && !horizontal) {
      return window.scrollY || window.pageYOffset;
    } else if (
      this.scrollableAncestor?.constructor === window.Window &&
      horizontal
    ) {
      return window.scrollX || window.pageXOffset;
    } else if (this.scrollableAncestor && horizontal) {
      return this.scrollableAncestor.scrollLeft;
    } else if (this.scrollableAncestor) {
      return this.scrollableAncestor.scrollTop;
    } else {
      return null;
    }
  };

  getScrollableAncestorSize = (): number | null => {
    const { horizontal } = this.props;
    if (this.scrollableAncestor?.constructor === window.Window && !horizontal) {
      return window.innerHeight;
    } else if (
      this.scrollableAncestor?.constructor === window.Window &&
      horizontal
    ) {
      return window.innerWidth;
    } else if (this.scrollableAncestor && horizontal) {
      return this.scrollableAncestor?.offsetWidth;
    } else if (this.scrollableAncestor) {
      return this.scrollableAncestor?.offsetHeight;
    } else {
      return null;
    }
  };

  removeEventListeners = (scrollableAncestor) => {
    scrollableAncestor.removeEventListener(
      'scroll',
      this.renderItemsScrollBinding
    );
    if (this.props.handleReportItemsInViewport) {
      scrollableAncestor.removeEventListener(
        'scroll',
        this.readAndReportItemsInViewport
      );
    }
    if (this.handleReportItemsInViewportTimeout) {
      window.clearTimeout(this.handleReportItemsInViewportTimeout);
    }
  };

  /* Executed either on `componentDidMount` or `componentDidUpdate` */
  setupEventsAndReportItems() {
    this.readAndReportItemsInViewport();
    this.setItemsToRender();
    this.renderItemsScrollBinding = throttle(this.setItemsToRender, 100, {
      trailing: true,
      leading: false,
    });

    if (this.scrollableAncestor && this.renderItemsScrollBinding) {
      this.scrollableAncestor.addEventListener(
        'scroll',
        this.renderItemsScrollBinding
      );
    }
    if (this.props.handleReportItemsInViewport && this.scrollableAncestor) {
      this.scrollableAncestor.addEventListener(
        'scroll',
        this.readAndReportItemsInViewport
      );
      if (!React.Children.count(this.props.children)) {
        this.props.handleReportItemsInViewport([], 0);
      }
    }
  }

  getItemComponentsInViewport = (): React.ReactNode[] => {
    const offsetSize = this.getScrollableAncestorSize();
    const scrollPos = this.getScrollableAncestorScrollPosition();
    const { horizontal } = this.props;

    if (offsetSize !== null && scrollPos !== null) {
      return Object.keys(this.itemNodesById).filter((key) => {
        const o = this.itemNodesById[key];
        const itemNode = findDOMNode(o);
        const cardOffset = horizontal
          ? itemNode.offsetLeft - scrollPos + itemNode.offsetWidth
          : itemNode.offsetTop - scrollPos + itemNode.offsetHeight;
        return cardOffset < offsetSize && cardOffset >= 0;
      });
      /* When called before the component mounts */
    } else {
      return [];
    }
  };

  getItemComponentsToRender = (): React.ReactNode[] => {
    const { shouldKeepItemsRendered, horizontal } = this.props;
    const { itemComponentsToRender } = this.state;
    const offsetSize = this.getScrollableAncestorSize();
    const scrollPos = this.getScrollableAncestorScrollPosition();
    const effectivePreloadBuffer = this.props.preloadBuffer || 0;
    if (offsetSize !== null && scrollPos !== null) {
      return Object.keys(this.itemNodesById)
        .filter((key) => {
          const o = this.itemNodesById[key];
          const itemNode = findDOMNode(o);
          const cardOffset = horizontal
            ? itemNode.offsetLeft - scrollPos + itemNode.offsetWidth
            : itemNode.offsetTop - scrollPos + itemNode.offsetHeight;
          const itemLLKey = getIsReactElement(o) && o.props.llKey;

          /* If we want to keep rendered items rendered and the item we're on is already rendered... */
          if (
            shouldKeepItemsRendered &&
            itemComponentsToRender.find(
              (item) =>
                getIsReactElement(item) && item.props.llKey === itemLLKey
            )
          ) {
            return true;
          }

          /* If setting a static height for the cards, we'll un-render items above the viewport
           * for performance */
          if (
            (
              this.props
                .itemDimensionsStyle as ItemsDimensionsStyleBaseWithHeight
            ).height
          ) {
            return (
              cardOffset <= offsetSize + effectivePreloadBuffer &&
              cardOffset >= 0 - effectivePreloadBuffer
            );
            /* If setting a minHeight (which allows items to be a dynamic height) we'll need to keep
             * items above the viewport rendered to avoid the list items changing positioning on the
             * page as you scroll */
          } else if (
            (
              this.props
                .itemDimensionsStyle as ItemsDimensionsStyleBaseWithMinHeight
            ).minHeight
          ) {
            return cardOffset <= offsetSize + effectivePreloadBuffer;
          } else {
            throw new Error(
              'Must provide either height or minHeight to in itemDimensionStyle prop'
            );
          }
        })
        .map((keys) => this.itemNodesById[keys]);
      /* When called before the component mounts */
    } else {
      return [];
    }
  };

  getChildChecksum = (children: React.ReactNode): string => {
    return React.Children.toArray(children)
      .map((child) => (child ? this.getItemLLKey(child) : ''))
      .join('');
  };

  /* Determines which item components to render using `preloadBuffer` and renders
   * them in the list.  Executed (and throttled) on scroll */
  setItemsToRender = () => {
    const {
      children,
      onReachedEnd,
      onScroll,
      shouldKeepItemsRendered,
      onRenderItems,
      horizontal,
    } = this.props;
    const { itemComponentsToRender } = this.state;

    if (onReachedEnd) {
      const offsetSize = this.getScrollableAncestorSize();
      const scrollPos = this.getScrollableAncestorScrollPosition();
      const node = findDOMNode(this);

      if (offsetSize !== null && scrollPos !== null) {
        const atEndScrollTop = horizontal
          ? node.clientWidth - offsetSize
          : node.clientHeight - offsetSize;
        if (scrollPos > 0 && atEndScrollTop - scrollPos < REACHED_END_BUFFER) {
          onReachedEnd();
        }
      }
    }

    if (onScroll) {
      onScroll();
    }

    /* If we want to keep items rendered and all are already rendered exit now for perf optimization */
    if (
      shouldKeepItemsRendered &&
      itemComponentsToRender.length === React.Children.count(children)
    ) {
      return;
    } else {
      const itemComponentsToRender = this.getItemComponentsToRender();
      this.setState({ itemComponentsToRender });

      if (onRenderItems) {
        onRenderItems(
          itemComponentsToRender.map(
            (item) => getIsReactElement(item) && item.props.llKey
          )
        );
      }
    }
  };

  /* Determines which items are within viewport and reports via callback */
  readAndReportItemsInViewport = () => {
    if (this.handleReportItemsInViewportTimeout) {
      window.clearTimeout(this.handleReportItemsInViewportTimeout);
    }

    if (this.props.handleReportItemsInViewport) {
      /* Debounce: only report items in viewport once scrolling stops */
      this.handleReportItemsInViewportTimeout = window.setTimeout(() => {
        const scrollTop = this.getScrollableAncestorScrollPosition();
        if (
          this.props.handleReportItemsInViewport &&
          typeof scrollTop === 'number'
        ) {
          this.props.handleReportItemsInViewport(
            this.getItemComponentsInViewport().map((o) =>
              getIsReactElement(o) ? o.props.llKey : o
            ),
            scrollTop
          );
        }
      }, REPORT_ITEMS_IN_VIEWPORT_DELAY);
    }
  };

  cacheItemComponent: React.LegacyRef<LazilyRenderedComponent> = (node) => {
    if (node && getIsReactElement(node)) {
      this.itemNodesById[node.props.llKey] = node;
    }
  };

  removeItemComponentFromCache = (key) => {
    delete this.itemNodesById[key];
  };

  getItemLLKey = (item) => {
    const { llKeyField } = this.props;
    return item.props?.[llKeyField];
  };

  render() {
    const { children, className, itemDimensionsStyle, itemClassName, style } =
      this.props;

    return (
      <ul style={style} className={classNames(className, theme.UlStyledAsDiv)}>
        {React.Children.map(children, (child) => {
          const key = (child && this.getItemLLKey(child)) as string | number;
          return (
            /* This component must be the same vertical size both before and after
             * its child content is rendered for lazy loading to work best */
            child && (
              <LazilyRenderedComponent
                key={key}
                llKey={key}
                className={itemClassName}
                style={itemDimensionsStyle}
                removeComponentFromCache={this.removeItemComponentFromCache}
                shouldRenderChild={
                  !!this.state.itemComponentsToRender.find(
                    (o) => getIsReactElement(o) && o.props.llKey === key
                  )
                }
                ref={this.cacheItemComponent}
              >
                {child}
              </LazilyRenderedComponent>
            )
          );
        })}
      </ul>
    );
  }
}

export default LazilyRenderedList;
