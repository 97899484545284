import React from 'react';

export const CloseIcon = ({ className, onClick }) => (
  <svg
    className={className}
    onClick={onClick}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
  >
    <g>
      <path d="M150.6,965.9c-32.2,32.2-84.3,32.2-116.5,0l0,0C2,933.6,2,881.7,34.1,849.3L849.4,34.2C881.6,2,933.7,2,965.9,34.2l0,0c32.2,32.2,32.2,84.3,0,116.5L150.6,965.9z" />
      <path d="M965.7,849.3c32.2,32.2,32.2,84.3,0,116.5l0,0c-32.2,32.2-84.3,32.2-116.4,0L34.1,150.6C2,118.3,2,66.4,34.1,34.2l0,0c32.2-32.3,84.3-32.3,116.5,0L965.7,849.3z" />
    </g>
  </svg>
);
