import React, { useEffect, useState } from 'react';
import { getColors } from '../utils/colors';

export default function Bars({
  numBars,
  xStart,
  yStart,
  xFin,
  yFin,
  opacity,
  strokeWidth,
  rotation,
}: {
  numBars: number;
  xStart: (i: number) => number;
  yStart: (i: number) => number;
  xFin: (i: number) => number;
  yFin: (i: number) => number;
  opacity?: number;
  strokeWidth?: number;
  rotation?: number;
}) {
  const [palette, setPalette] = useState<string[] | null>(null);

  useEffect(() => {
    setPalette(getColors(numBars, rotation || 60));
  }, []);

  return (
    palette && (
      <g>
        {[...Array(numBars)].map((_, i) => {
          return (
            <line
              key={i}
              x1={xStart(i)}
              y1={yStart(i)}
              x2={xFin(i)}
              y2={yFin(i)}
              style={{
                stroke: palette[i % numBars],
                strokeWidth: strokeWidth || 2,
                opacity,
              }}
            />
          );
        })}
      </g>
    )
  );
}
