let randomNum = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Here come the colors
class Color {
  minHue: number = 0;
  maxHue: number = 0;
  minSat: number = 0;
  maxSat: number = 0;
  minLight: number = 0;
  maxLight: number = 0;
  scaleLight: number = 0;
  hue: number = 0;
  sat: number = 0;
  light: number = 0;
  hsl: string | null = null;

  constructor(hue?: number, sat?: number, light?: number) {
    // Settings
    // Play with these to change the types of colors generated
    this.minHue = 0;
    this.maxHue = 360;

    this.minSat = 75;
    this.maxSat = 100;

    this.minLight = 65;
    this.maxLight = 80;

    this.scaleLight = 15;

    // Darker colors for a light background
    if (true) {
      this.minLight = 40;
      this.maxLight = 65;
    }

    // Set hue
    this.hue = hue || randomNum(this.minHue, this.maxHue);

    // Redo if ugly hue is generated
    // Because magenta is hideous
    if (this.hue > 288 && this.hue < 316) {
      this.hue = randomNum(316, 360);
    } else if (this.hue > 280 && this.hue < 288) {
      this.hue = randomNum(260, 280);
    }

    this.sat = sat || randomNum(this.minSat, this.maxSat);
    this.light = light || randomNum(this.minLight, this.maxLight);
    this.hsl = 'hsl(' + this.hue + ', ' + this.sat + '%, ' + this.light + '%)';
  }

  changeHue = (hue, rotate) => {
    return hue + rotate > this.maxHue
      ? hue + rotate - this.maxHue
      : hue + rotate;
  };

  changeLight = (light) => {
    return light + this.scaleLight > this.maxLight
      ? this.maxLight
      : light + this.scaleLight;
  };

  getHsl = () => {
    return this.hsl;
  };
}

export const getColors = (num: number, rotation: number) => {
  const baseColor = new Color();
  let output = [baseColor.hsl!];
  for (let i = 0; i < num; i++) {
    output.push(
      new Color(
        baseColor.changeHue(baseColor.hue, rotation * (i + 1)),
        baseColor.sat,
        baseColor.changeLight(baseColor.light)
      ).hsl!
    );
  }
  return output;
};
