export const getAlbumTitle = (s3AlbumKey: string | null) => {
  return s3AlbumKey
    ? s3AlbumKey
        .split('-')
        .map(
          (segment) =>
            `${segment.charAt(0).toUpperCase()}${segment.substring(1)}`
        )
        .join(' ')
    : null;
};
