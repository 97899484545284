import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import Bars from '../components/Bars';
import { getColors } from '../utils/colors';

const NUM_COLORS = 10;

const Index: React.FC<{ title: string }> = ({ title }) => {
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    setContainerDimensions({
      width: 500,
      height: window.innerHeight,
    });
  }, []);
  const letters = 'WillsPhotoSite'.split('');
  const letterOffsets = [0, 6, 3, 0.3, -2, 0, 3, 1, 2.5, 1, 0, 2.5, -0.2, -1.2];
  const aspectRatio = containerDimensions.width / containerDimensions.height;
  const SIZE_FACTOR = 22;
  const PADDING = 4;
  const CONTAINER_WIDTH = 600;
  const LETTERS_PER_ROW = 5;
  const SVG_BOX_WIDTH = CONTAINER_WIDTH - PADDING * SIZE_FACTOR;
  const LETTER_WIDTH = SVG_BOX_WIDTH / SIZE_FACTOR / LETTERS_PER_ROW;
  const EFFECTIVE_PAGE_WIDTH = SVG_BOX_WIDTH / SIZE_FACTOR;
  const palette = getColors(NUM_COLORS, 60);

  function getLetterDy(i: number) {
    return (
      Math.floor(i / LETTERS_PER_ROW) *
      (SVG_BOX_WIDTH / LETTERS_PER_ROW / (SIZE_FACTOR - 14))
    );
  }

  return containerDimensions.width ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg
        viewBox={`0 0 ${CONTAINER_WIDTH / SIZE_FACTOR} ${
          CONTAINER_WIDTH / aspectRatio / SIZE_FACTOR
        }`}
        style={{
          cursor: 'pointer',
          width: '100%',
          height: '100%',
        }}
        onClick={() => {
          navigate('/albums/');
        }}
      >
        <Bars
          numBars={40}
          rotation={12}
          xStart={(i) => i * 4 - 100}
          xFin={(i) => i * 4 + 120}
          yStart={() => -1}
          yFin={(i) => 110}
          opacity={0.1}
          strokeWidth={1.8}
        />
        {letters.map((letter, i) => {
          const LETTER_DX =
            ((LETTER_WIDTH * i) % EFFECTIVE_PAGE_WIDTH) + letterOffsets[i];
          const LETTER_DY = getLetterDy(i);
          return (
            <text
              key={i}
              style={{
                fill: palette[i % NUM_COLORS],
              }}
              x={0}
              y={13}
              dx={LETTER_DX}
              dy={LETTER_DY}
            >
              {letter}
            </text>
          );
        })}
        <Bars
          numBars={30}
          rotation={30}
          xStart={(i) => i * 8 - 100}
          xFin={(i) => i * 8 - 100 + 200}
          yStart={() => 42}
          yFin={(i) => 200}
          strokeWidth={5}
        />
      </svg>
    </div>
  ) : null;
};

export default Index;

export function Head({ params }) {
  const resolvedTitle = "Will's Photosite";
  return (
    <>
      <title>{resolvedTitle}</title>
    </>
  );
}
