import Bars from '../components/Bars';
import React from 'react';

export default function Logo({
  className,
  onClick,
}: {
  className?: string;
  onClick: () => void;
}) {
  return (
    <svg
      viewBox={`0 0 10 10`}
      className={className}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <Bars
        numBars={6}
        rotation={30}
        xStart={(i) => i * 3 - 9}
        xFin={(i) => i * 3 - 30 + 200}
        yStart={() => 0.7}
        yFin={(i) => 200}
        strokeWidth={2.25}
      />
    </svg>
  );
}
