import { S3Client, ListObjectsV2Command } from '@aws-sdk/client-s3';

const client = new S3Client({
  region: 'us-west-2',
  credentials: {
    /* Note: these creds belong to a Role with extremely limited access - just ListBucket on the public willphotos bucket */
    accessKeyId: 'AKIAWKYOJBBLER3WHI5Y',
    secretAccessKey: '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',
  },
});

const isDefined = (i: string | undefined): i is string => !!i;

export const fetchBucketData = (options?: {
  Prefix?: string;
  filterFunc?: typeof isDefined;
}): Promise<string[]> => {
  const resultsCollection: string[] = [];
  const effectiveFilterFunc = options?.filterFunc || isDefined;

  const iterate = ({
    resolve,
    reject,
    ContinuationToken,
  }: {
    resolve: (value: string[] | PromiseLike<string[]>) => void;
    reject: (reason?: any) => void;
    ContinuationToken?: string;
  }) => {
    const command = new ListObjectsV2Command({
      Bucket: 'willphotos',
      ContinuationToken,
      ...options,
    });
    client.send(command).then(
      (data) => {
        const bucketKeys =
          data?.Contents?.map((object) => object.Key).filter(
            effectiveFilterFunc as typeof isDefined
          ) || null;
        if (bucketKeys) {
          resultsCollection.push(...bucketKeys);
        }
        if (data.IsTruncated) {
          iterate({
            resolve,
            reject,
            ContinuationToken: data.NextContinuationToken,
          });
        } else {
          resolve(resultsCollection);
        }
      },
      (error) => {
        reject(error);
      }
    );
  };

  return new Promise((resolve, reject) => {
    iterate({ resolve, reject });
  });
};
